/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import cameraIcon from '../../assets/map/camera.png';
import stopIcon from '../../assets/map/stop.png';
import dogWalkingPic from '../../assets/map/dogwalkingpic.png';
import useFetch from '../../hooks/useFetch';
import { userDataState } from '../../stores/auth/authState';
import dogOne from '../../assets/dog/dogOne.png';
import dogTwo from '../../assets/dog/dogTwo.png';
import dogThree from '../../assets/dog/dogThree.png';
import dogFour from '../../assets/dog/dogFour.png';
import { ResDogType } from '../../types/dogTypes';

const ModalContainer = styled.div`
  width: 336px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow:
    0px 3px 7px 0px #309c7133,
    0px 13px 13px 0px #309c712b,
    0px 30px 18px 0px #309c711a,
    0px 53px 21px 0px #309c7108,
    0px 82px 23px 0px #309c7100;
  height: 214px;
  position: fixed;
  bottom: 50px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 98px;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  align-items: center;

  .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 23.87px;
    color: ${(props) => props.theme.colors.darkGray};
    text-align: center;
    width: 115px;
  }
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 14px;
`;

const PhotoCount = styled.span`
  font-size: 20px;
  line-height: 23.4px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary[5]};
`;

const CameraButton = styled.button`
  background: none;
  border: none;
  margin-left: 11px;
  cursor: pointer;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 116px;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  width: 112px;

  .title {
    font-size: 20px;
    font-weight: 400;
    line-height: 23.87px;
    color: ${(props) => props.theme.colors.primary[5]};
  }

  .content {
    font-size: 20px;
    font-weight: 700;
    line-height: 23.87px;
    color: ${(props) => props.theme.colors.darkGray};
  }
`;

const StopButton = styled.button`
  background: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 20px 0;
`;

const ImgContainer = styled.div`
  position: relative;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  overflow: hidden;
`;

const Img = styled.img<{ clipPath: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: ${({ clipPath }) => clipPath};
`;

const getClipPath = (numDogs: number, index: number): string => {
  switch (numDogs) {
    case 1:
      return 'circle(50% at 50% 50%)';
    case 2:
      return index === 0
        ? 'polygon(0 0, 50% 0, 50% 100%, 0 100%)'
        : 'polygon(50% 0, 100% 0, 100% 100%, 50% 100%)';
    case 3:
      return index === 0
        ? 'polygon(0 0, 33.33% 0, 33.33% 100%, 0 100%)'
        : index === 1
          ? 'polygon(33.33% 0, 66.66% 0, 66.66% 100%, 33.33% 100%)'
          : 'polygon(66.66% 0, 100% 0, 100% 100%, 66.66% 100%)';
    case 4:
      return index === 0
        ? 'polygon(0 0, 50% 0, 50% 50%, 0 50%)'
        : index === 1
          ? 'polygon(50% 0, 100% 0, 100% 50%, 50% 50%)'
          : index === 2
            ? 'polygon(0 50%, 50% 50%, 50% 100%, 0 100%)'
            : 'polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%)';
    default:
      return 'circle(50% at 50% 50%)';
  }
};

const WalkingModal = ({
  distance,
  time: elapsedTime,
  onStop,
  onTakePhoto,
  photoCount,
  dogChange,
  selectedDogs,
}: {
  distance: number;
  time: number;
  onStop: () => void;
  onTakePhoto: () => void;
  photoCount: number;
  dogChange: () => void;
  selectedDogs: number[];
}) => {
  const userData = useRecoilValue(userDataState);
  const { data: dogData, isLoading } = useFetch<ResDogType>('/dog', '/dog', {});

  const formatTime = (time: number) => {
    const hours = String(Math.floor(time / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const formatNumber = (num: number): string => {
    return num.toString().padStart(3, '0');
  };

  const formatDistance = (distanceNum: number): string => {
    return distanceNum.toFixed(2).padStart(5, '0');
  };

  const [imgSrc, setImgSrc] = useState(dogOne);
  const handleError = () => {
    setImgSrc(dogOne);
  };

  const getDogImages = () => {
    if (!dogData) return [];
    return selectedDogs.map((dogId) => {
      const dog = dogData.data.find((d) => d.dogId === dogId);
      return dog?.profile || dogOne; // 프로필 이미지가 없으면 기본 이미지 사용
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ModalContainer>
      <Header>
        <HeaderBox>
          <ImgContainer>
            {getDogImages().map((imgSrc, index) => (
              <Img
                key={imgSrc}
                src={imgSrc}
                alt={`dog${index + 1}`}
                onClick={dogChange}
                clipPath={getClipPath(selectedDogs.length, index)}
              />
            ))}
          </ImgContainer>
          <span className="title">즐겁게 산책중!</span>
        </HeaderBox>
        <HeaderBox>
          <PhotoCount>{formatNumber(photoCount)}</PhotoCount>
          <CameraButton onClick={onTakePhoto}>
            <img src={cameraIcon} alt="camera" width={40} height={40} />
          </CameraButton>
        </HeaderBox>
      </Header>
      <Info>
        <InfoItem>
          <div className="title">거리</div>
          <div className="content">{formatDistance(distance)} km</div>
        </InfoItem>
        <StopButton onClick={onStop}>
          <img src={stopIcon} alt="stop" width={76} height={76} />
        </StopButton>
        <InfoItem>
          <div className="title">시간</div>
          <div className="content">{formatTime(elapsedTime)}</div>
        </InfoItem>
      </Info>
    </ModalContainer>
  );
};

export default WalkingModal;
